@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  table {
    th {
      background-color: transparent;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      text-transform: uppercase;
      color: $color-text-dark;
    }

    td {
      padding: 8px 10px;
    }
  }
}

.noEdit {
  table {
    border-collapse: separate;
    border-spacing: 0 15px;

    th {
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
    }

    tbody {
      td {
        background-color: unset;
        padding: 0;
      }

      tr:nth-child(2n + 2) {
        td {
          background: rgba(239, 234, 232, 0.29);
        }
      }
    }
  }

  .productInfo {
    padding-left: 10px;
  }
}

.small {
  &.productThumb {
    width: 68px;
  }

  .producerName {
    font-size: 12px !important;
    margin-bottom: 7px !important;
  }

  .productName {
    font-size: 10px !important;
    margin-bottom: 0 !important;
  }

  .productSize {
    font-size: 10px !important;
    margin-top: 7px;
  }

  &.productInfo {
    font-size: 10px;
  }

  &.quantityCellWrapper {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
  }

  &.productTotalPrice {
    text-align: right;
    font-size: 10px;
    line-height: 120%;

    > span {
      text-transform: none;
    }

    .netto {
      white-space: nowrap;

      span {
        text-transform: none;
        font-size: 12px;
      }
    }

    .gross {
      white-space: nowrap;
    }
  }
}

.productThumb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.productInfo {
  max-width: 185px;
  text-align: left;
  @include breakpoint(sm) {
    margin-top: -10px;
  }
  .grey {
    font-weight: 400;
    color: $color-text;
    font-size: 10px;
    margin-bottom: 4px;
  }

  a {
    text-decoration: none;
  }

  .producerName {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-transform: uppercase;
    margin-bottom: 12px;
    color: $color-text-light;
    @include breakpoint(sm) {
      font-size: 10px;
      margin-bottom: 6px;
    }
  }

  .productName {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: $color-text-dark;
    margin-bottom: 14px;
    @include breakpoint(sm) {
      margin-bottom: 2px;
    }
  }

  .productSize {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    display: block;
    > span {
      @include breakpoint(sm) {
        font-weight: 600;
      }
    }
  }

  .productColor {
    margin-top: 7px;
  }
}

.productAvailability {
  display: flex;
  justify-content: center;

  @include breakpoint(sm) {
    justify-content: flex-start;
  }
}

.quantityCellWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include breakpoint(sm) {
    align-items: self-start;
  }

  > div + div {
    margin-top: 10px;
  }
}

.productPrice,
.productTotalPrice {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: $color-text-dark;
  text-align: center;
  margin: 0 auto;

  @include breakpoint(sm) {
    text-align: left;
  }
}

.productUnit {
  color: $color-text;

  .descriptionLine {
    display: block;
    font-size: 10px;
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  color: $color-text-light;

  > svg {
    width: 16px;
    height: 16px;
    cursor: pointer;

    @include breakpoint(sm) {
      width: 20px;
      height: 20px;
    }

    &:first-child {
      margin-bottom: 8px;
    }
  }
}

.confirmationModal {
  text-align: left;
}

.confirmationModalActions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  border-top: 1px solid $color-gray-background-dark;
  padding-top: 20px;

  button + button {
    margin-left: 16px;
  }
}

.dropdownButton {
  border: none;
  background: transparent;
  padding: 0 30px 0 0;
  position: relative;
  cursor: pointer;

  &:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 6px 5px;
    border-color: transparent transparent $color-gray-border transparent;
    position: absolute;
    top: 18px;
    right: 14px;
    transform: rotate(180deg);
  }

  &.open:after {
    transform: rotate(0);
    border-color: transparent transparent $color-secondary transparent;
  }
}

.menu {
  .MuiPaper-root {
    box-shadow: none;
    border: 1px solid $color-secondary-border;
  }
}

.menuItem {
  &.MuiMenuItem-root {
    color: $color-text;
  }

  &.MuiMenuItem-root:hover {
    color: $color-secondary;
    background-color: $color-gray-background;
  }

  > svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}
.discount {
  color: $color-red;
  font-weight: 700;
}

.oldPrice,
.discount {
  @include breakpoint(sm) {
    line-height: 140% !important;
  }
}

.oldPrice {
  text-decoration: line-through;
}

.isCheckout {
  .productInfo {
    @include breakpoint(sm) {
      padding-left: 5px;
    }
  }
  .productName {
    @include breakpoint(sm) {
      font-size: 8px;
    }
  }
  .producerName,
  .productSize {
    @include breakpoint(sm) {
      font-size: 10px;
    }
  }
  .productPrice,
  .productTotalPrice {
    @include breakpoint(sm) {
      width: 54px;
      font-size: 10px;
    }
  }
}
