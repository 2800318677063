@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapperComponent {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 880px;
  margin: 0 auto;

  @include breakpoint(sm) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }

  @include breakpoint(lg) {
    padding: 0 15px;
  }
}

.firstLevel {
  padding: 20px 0;
  cursor: pointer;

  @include breakpoint(md) {
    padding: 8px 0 8px 0;
  }

  > span {
    padding-bottom: 5px;

    @include breakpoint(sm) {
      > a {
        font-size: 18px;
        font-weight: 600;
        color: $color-text;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 850px) {
      font-size: 13px !important;
    }
  }

  a {
    text-decoration: none;
    color: unset;
  }

  .collapseWrapper {
    > div {
      margin-bottom: 0;

      > div:first-child {
        span {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
          color: $color-text;
          padding-bottom: 10px;
          border-bottom: 1px solid $color-primary;
          width: 120px;
          margin-bottom: 20px;

          @include breakpoint(md) {
            padding-bottom: 7px;
          }
        }

        @include breakpoint(md) {
          div {
            max-width: unset;
            align-items: flex-start;

            svg {
              margin-top: 5px;
            }
          }

          span {
            width: 300px;
            max-width: unset;
          }
        }

        @include breakpoint(sm) {
          span {
            width: 120px;
            max-width: unset;
          }
        }
      }
    }
  }

  @include breakpoint(md) {
    div {
      justify-content: space-between;
    }
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $color-text-dark;
    text-transform: uppercase;
    display: block;

    &:hover {
      color: $color-highlighted-position;
    }
  }

  &.active {
    > span {
      padding-bottom: 4px;
      border-bottom: 1px solid $color-highlighted-position;
      color: $color-highlighted-position;
    }
  }

  &.news {
    span {
      font-weight: 600;
      color: $color-orange;

      @include breakpoint(md) {
        font-size: 14px;
        margin-bottom: 12px;
        margin-top: 12px;

        a {
          color: $color-primary !important;
        }
      }

      @include breakpoint(sm) {
        margin-top: 28px;
      }
    }
  }

  &.promotion {
    span {
      a {
        font-weight: 600;
        color: $color-red;
      }

      @include breakpoint(sm) {
        font-size: 18px;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
  }
}

.mainLevel {
  margin-bottom: 10px;

  @include breakpoint(md) {
    margin-bottom: 10px;
  }

  > span {
    padding-right: 15px;
    display: block;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    color: $color-black;
    padding-bottom: 10px;
    border-bottom: 1px solid #df992f;
    text-transform: uppercase;
    white-space: nowrap;

    @include breakpoint(md) {
      border-bottom: unset;
      color: $color-text;
    }

    &:hover {
      border-bottom: 1px solid $color-highlighted-position;
    }
  }
}

.secondary {
  padding-right: 20px;
  > span {
    text-transform: initial;
  }
}

.highlighted {
  > span {
    color: $color-highlighted-position;
  }
}

.category {
  width: 100%;
  position: relative;

  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &:not(.main) {
    & > span {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 140%;
      margin-bottom: 10px;
      display: block;
    }
  }

  @include breakpoint(sm) {
    .category {
      margin-left: 20px;

      span {
        font-size: 13px !important;
      }
    }

    & > span {
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      border-bottom: unset;
      margin-bottom: 7px;
      padding-bottom: 0;
      color: $color-text !important;
    }
  }
}

.menuWrapper {
  margin-top: 20px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 999;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.categoryWrapper {
  background-color: $color-menu-background;
  display: flex;

  & > div:first-child {
    display: flex;
    flex-basis: 0;
    justify-content: flex-start;
    grid-column-gap: 55px;
    columns: 5;
    padding: 25px 55px;
    width: 83%;
  }

  img {
    display: block;
  }
}

.imageWrapper {
  p {
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.StylePath-Components-Controls-Collapse {
  display: none !important;
}
